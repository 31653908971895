

const OpenTabsByHash = (collapse) => {
    const openedHeader = collapse.querySelector('header[aria-expanded="true"]')
    if (!openedHeader) return

    const tabPaneParent = openedHeader.closest('.tab-pane')
    if (!tabPaneParent) return

    const navTabsParent = tabPaneParent.parentElement.previousElementSibling
    if (!navTabsParent) return

    const correspondingNavLink = navTabsParent.querySelector(`.nav-link[aria-controls="${tabPaneParent.id}"]`)
    if (correspondingNavLink) {
        correspondingNavLink.click()
    }
}

const collapseOpenItemWhenFragment = () => {
    const hash = window.location.hash
    if (hash) {
        const node = document.querySelector(`.collapse-box header[id^='trigger'] a[href='${hash}']`)
        node && node.click()
    }
}

const openAccordingCollapse = () => {
    const collapses = document.querySelectorAll('.collapse-box')
    const hasHash = !!window.location.hash

    for (const collapse of collapses) {
        const triggerOpenHeader = collapse.querySelector('header[id^="trigger-open"]')
        const openAside = collapse.querySelector('aside[id^="open"]')

        // Open first collapse if no hash is present
        if (!hasHash && triggerOpenHeader && openAside) {
            triggerOpenHeader.classList.remove('collapsed')
            triggerOpenHeader.setAttribute('aria-expanded', 'true')
            openAside.classList.add('collapse', 'show')
            console.log('ano')
        }

        if (hasHash) {
            // Open collapse and tab by hash
            collapseOpenItemWhenFragment()
            OpenTabsByHash(collapse)
        } else {
            // Open first nav tab if no hash is present
            const tabs = document.querySelectorAll('.nav.nav-tabs')
            for (const tab of tabs) {
                const firstNavItemButton = tab.querySelector('.nav-item button')
                if (firstNavItemButton) {
                    firstNavItemButton.click()
                }
            }
        }
    }
}

const collapseAddNameToItems = () => {
    for (const elem of document.querySelectorAll(".collapse-box header[id^='trigger'] a")) {
        if (!elem.hasAttribute('name')) {
            elem.setAttribute('name', elem.getAttribute('href').substring(1))
            elem.addEventListener('click', (event) => {
                event.preventDefault()
                history.pushState ?
                    history.pushState(null, null, event.target.getAttribute('href')) :
                    location.hash = event.target.getAttribute('href')
            })
        }
    }
}

export { openAccordingCollapse, collapseAddNameToItems, collapseOpenItemWhenFragment }
