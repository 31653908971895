import { setCMSBarWidth } from './cms-bar-width'


import './select_checkbox'
import './required_checkbox'
import {
    openAccordingCollapse,
    collapseAddNameToItems
} from './collapsers'
import { initMenuControl } from './menu_control'

document.addEventListener('DOMContentLoaded', () => {
    // Because message 'Enter a valid email address.' is hidden.
    for (const email of document.querySelectorAll('.base-form input[type=email].has-error')) {
        if (email.value) {
            email.classList.add('email-error')
        }

        email.addEventListener('input', () => {
            email.classList.remove('email-error')
        })
    }

    /* Add class active-submenu */
    const menuWrapper = document.getElementsByClassName('menu-wrapper')
    if (menuWrapper.length > 0) {
        document.getElementsByClassName('container')[0].classList.add("active-submenu")
    }

    for (const svg of document.querySelectorAll('.cooperation aside img')) {
        svg.insertAdjacentHTML('afterend', `<div class="info-tooltip" >${svg.alt}</div>`)
    }

    for (const item of document.querySelectorAll('.prevent-default')) {
        item.href = ''
    }

    openAccordingCollapse()
    collapseAddNameToItems()
    initMenuControl()

    const structure = document.querySelector("a[href=?structure]")
    if (structure) {
        structure.addEventListener('click', () => {setTimeout(setCMSBarWidth, 1000)}, true)
    }
})

import './cms_toolbar'
